import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

type NodeSelector = {
  node1: HTMLElement | null
  node2: HTMLElement | null
  nodeComesAfter: (node1: HTMLElement, node2: HTMLElement) => boolean
  selectNodeRange: () => void
  checkNode: (node: HTMLInputElement) => void
}

const nodeSelector: NodeSelector = {
  node1: null,
  node2: null,

  selectNodeRange() {
    const {node1, node2} = nodeSelector
    if (node1 && node2) {
      // swap the nodes around if they went in reverse
      if (nodeSelector.nodeComesAfter(node1, node2)) {
        const n = nodeSelector.node1
        nodeSelector.node1 = nodeSelector.node2
        nodeSelector.node2 = n
      }

      let nextNode = node1.nextElementSibling
      while (nextNode) {
        if (nextNode === node2) {
          break
        }
        nextNode = nextNode.nextElementSibling
      }
    }
  },

  nodeComesAfter(node1, node2) {
    let el = node1.previousElementSibling
    while (el) {
      if (el === node2) {
        return true
      }
      el = el.previousElementSibling
    }
    return false
  },

  checkNode(nodeCheckbox) {
    const node = nodeCheckbox.closest<HTMLElement>('.js-wiki-history-revision')!
    // if we're unchecking
    if (!nodeCheckbox.checked) {
      // no longer track this
      if (nodeSelector.node1 && node === nodeSelector.node1) {
        nodeSelector.node1 = null
        if (nodeSelector.node2) {
          nodeSelector.node1 = nodeSelector.node2
          nodeSelector.node2 = null
        }
      } else if (nodeSelector.node2 && node === nodeSelector.node2) {
        nodeSelector.node2 = null
      }
    } else {
      if (!nodeSelector.node1) {
        nodeSelector.node1 = node
      } else if (!nodeSelector.node2) {
        // okay, we don't have a node 2 but have a node1
        nodeSelector.node2 = node
        nodeSelector.selectNodeRange()
      } else {
        // we have two selected already
        nodeCheckbox.checked = false
      }
    }
  },
}

observe('.js-wiki-history-checkbox', function (el) {
  if (!(el instanceof HTMLInputElement)) return
  if (el.checked) {
    nodeSelector.checkNode(el)
  }
})

on('change', '.js-wiki-history-checkbox', function ({currentTarget}) {
  if (!(currentTarget instanceof HTMLInputElement)) return
  nodeSelector.checkNode(currentTarget)
})
